var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('k-crud-page',{scopedSlots:_vm._u([{key:"condition",fn:function(){return [_c('div',{staticClass:"d-flex flex-wrap my-4"},[_c('k-text-input',{staticClass:"my-1",attrs:{"label":"角色名称"},model:{value:(_vm.cruddataMX.conditions.roleName),callback:function ($$v) {_vm.$set(_vm.cruddataMX.conditions, "roleName", $$v)},expression:"cruddataMX.conditions.roleName"}}),_c('k-select',{attrs:{"items":_vm.statusItems,"label":"启用状态"},model:{value:(_vm.cruddataMX.conditions.status),callback:function ($$v) {_vm.$set(_vm.cruddataMX.conditions, "status", $$v)},expression:"cruddataMX.conditions.status"}})],1),_c('div',{staticClass:"d-flex align-center"},[_c('k-primary-button',{staticClass:"my-1 mr-4",attrs:{"text":"检索","icon":"mdi-magnify"},on:{"click":function($event){_vm.crudActionMX().list()}}}),_c('k-warning-btn',{staticClass:"my-1 mr-4",attrs:{"text":"重置","icon":"mdi-restore"},on:{"click":function($event){_vm.crudConditionsMX().reset()}}})],1)]},proxy:true},{key:"resultlist",fn:function(){return [_c('k-crud-table',{attrs:{"headers":_vm.columns,"items":_vm.cruddataMX.dataList,"value":_vm.cruddataMX.pagination.current,"total":_vm.cruddataMX.pagination.total,"sort-by":_vm.cruddataMX.ascList,"sort-desc":_vm.cruddataMX.descList,"multi-sort":"","item-key":"uuid"},on:{"pageChanged":function (page) {
            _vm.crudPaginationMX().change(page);
          },"sort":function (options) {
            _vm.crudPaginationMX().sort(options);
          }},scopedSlots:_vm._u([{key:"status",fn:function(ref){
          var item = ref.item;
return [(item.roleId !== '2')?_c('k-switch',{attrs:{"readonly":!_vm.hasPermission(['role.edit']),"true-value":"1","false-value":"-1","label":item.status ? '启用中' : '禁用中',"loading":item.loading},on:{"change":function($event){return _vm.onChangeStatus(item)}},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}}):_vm._e()]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("数据列表")]),_c('v-spacer'),_c('k-primary-button',{staticClass:"mr-2",attrs:{"icon":"mdi-account-multiple-plus","text":"分组管理"},on:{"click":_vm.onOpenGroupEdit}}),(_vm.hasPermission(['role.add']))?_c('k-primary-button',{staticClass:"mr-2",attrs:{"icon":"mdi-account-plus","text":"新增角色"},on:{"click":function($event){_vm.crudActionMX().newItem(_vm.crud.default, _vm.onOpenEdit(_vm.crud.default))}}}):_vm._e()],1)]},proxy:true},{key:"actions",fn:function(ref){
          var item = ref.item;
return [(_vm.hasPermission(['role.edit']) && item.roleId !== '2')?_c('k-table-warning-btn',{staticClass:"mr-2",attrs:{"text":"编辑"},on:{"click":function($event){_vm.crudActionMX().editItem(item, _vm.onOpenEdit(item))}}}):_vm._e(),(_vm.hasPermission(['role.delete']) && item.roleId !== '2')?_c('k-table-error-btn',{attrs:{"text":"删除"},on:{"click":function($event){_vm.crudActionMX().deleteItem({ id: item.uuid })}}}):_vm._e()]}}])})]},proxy:true}])}),_c('k-crud-dialog',{attrs:{"title":"角色分组管理"},on:{"close":function($event){_vm.groupEditDialog = false},"save":_vm.saveGroupEdit},scopedSlots:_vm._u([{key:"dialogContent",fn:function(){return [_c('k-crud-table',{attrs:{"paginationshow":false,"elevation":false,"items":_vm.groupItems,"hide-default-footer":"","loading":_vm.groupEditOpenLoading,"headers":_vm.groupColumns},scopedSlots:_vm._u([{key:"roleName",fn:function(ref){
          var item = ref.item;
return [(item.roleName)?_c('span',[_vm._v(" "+_vm._s(item.roleName)+" ")]):_vm._e()]}},{key:"actions",fn:function(ref){
          var item = ref.item;
          var index = ref.index;
return [(item.roleId !== '1')?_c('v-icon',{attrs:{"color":"error"},on:{"click":function($event){return _vm.delGroup(item, index)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}},{key:"append",fn:function(){return [_c('tr',[_c('td',{staticClass:"text-center info--text"},[_c('k-text-input',{attrs:{"placeholder":"请输入角色分组名称"},model:{value:(_vm.groupEditingName),callback:function ($$v) {_vm.groupEditingName=$$v},expression:"groupEditingName"}})],1),_c('td',[_c('v-icon',{attrs:{"color":"primary"},on:{"click":_vm.newGroup}},[_vm._v(" mdi-plus ")])],1)])]},proxy:true}])})]},proxy:true}]),model:{value:(_vm.groupEditDialog),callback:function ($$v) {_vm.groupEditDialog=$$v},expression:"groupEditDialog"}}),_c('k-crud-dialog',{attrs:{"title":_vm.crud.title},on:{"save":function () {
        _vm.crudActionMX().saveItem();
      },"close":function($event){_vm.crud.switch = false}},scopedSlots:_vm._u([{key:"dialogContent",fn:function(){return [_c('v-form',{ref:_vm.crud.form.ref},[_c('div',{staticClass:"d-flex flex-column"},[_c('k-text-input',{staticClass:"my-2",attrs:{"label":"角色名称","rules":[_vm.rules.required]},model:{value:(_vm.cruddataMX.data.roleName),callback:function ($$v) {_vm.$set(_vm.cruddataMX.data, "roleName", $$v)},expression:"cruddataMX.data.roleName"}}),_c('k-select',{staticClass:"my-2",attrs:{"items":_vm.crud.select.roleGroups.dataset,"label":"角色分组","loading":_vm.crud.select.roleGroups.loading,"disabled":_vm.crud.select.roleGroups.loading},model:{value:(_vm.cruddataMX.data.parentId),callback:function ($$v) {_vm.$set(_vm.cruddataMX.data, "parentId", $$v)},expression:"cruddataMX.data.parentId"}}),_c('k-radio-group',{staticClass:"my-2",attrs:{"label":"启用状态","items":_vm.statusItems},model:{value:(_vm.cruddataMX.data.status),callback:function ($$v) {_vm.$set(_vm.cruddataMX.data, "status", $$v)},expression:"cruddataMX.data.status"}}),_c('k-text-area',{staticClass:"my-2",attrs:{"label":"备注"},model:{value:(_vm.cruddataMX.data.remarks),callback:function ($$v) {_vm.$set(_vm.cruddataMX.data, "remarks", $$v)},expression:"cruddataMX.data.remarks"}})],1)])]},proxy:true}]),model:{value:(_vm.crud.switch),callback:function ($$v) {_vm.$set(_vm.crud, "switch", $$v)},expression:"crud.switch"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }