<template>
  <div>
    <k-crud-page>
      <template #condition>
        <div class="d-flex flex-wrap my-4">
          <k-text-input
            v-model="cruddataMX.conditions.roleName"
            class="my-1"
            label="角色名称"
          />
          <k-select
            v-model="cruddataMX.conditions.status"
            :items="statusItems"
            label="启用状态"
          />
        </div>
        <div class="d-flex align-center">
          <k-primary-button
            class="my-1 mr-4"
            text="检索"
            icon="mdi-magnify"
            @click="crudActionMX().list()"
          />
          <k-warning-btn
            class="my-1 mr-4"
            text="重置"
            icon="mdi-restore"
            @click="crudConditionsMX().reset()"
          />
        </div>
      </template>
      <template #resultlist>
        <k-crud-table
          :headers="columns"
          :items="cruddataMX.dataList"
          :value="cruddataMX.pagination.current"
          :total="cruddataMX.pagination.total"
          :sort-by="cruddataMX.ascList"
          :sort-desc="cruddataMX.descList"
          multi-sort
          item-key="uuid"
          @pageChanged="
            (page) => {
              crudPaginationMX().change(page);
            }
          "
          @sort="
            (options) => {
              crudPaginationMX().sort(options);
            }
          "
        >
          <template #status="{ item }">
            <k-switch
              v-if="item.roleId !== '2'"
              v-model="item.status"
              :readonly="!hasPermission(['role.edit'])"
              true-value="1"
              false-value="-1"
              :label="item.status ? '启用中' : '禁用中'"
              :loading="item.loading"
              @change="onChangeStatus(item)"
            ></k-switch>
          </template>

          <template #top>
            <v-toolbar flat>
              <v-toolbar-title>数据列表</v-toolbar-title>
              <v-spacer></v-spacer>
              <k-primary-button
                class="mr-2"
                icon="mdi-account-multiple-plus"
                text="分组管理"
                @click="onOpenGroupEdit"
              />
              <k-primary-button
                v-if="hasPermission(['role.add'])"
                class="mr-2"
                icon="mdi-account-plus"
                text="新增角色"
                @click="
                  crudActionMX().newItem(crud.default, onOpenEdit(crud.default))
                "
              />
            </v-toolbar>
          </template>
          <template #actions="{ item }">
            <k-table-warning-btn
              v-if="hasPermission(['role.edit']) && item.roleId !== '2'"
              text="编辑"
              class="mr-2"
              @click="crudActionMX().editItem(item, onOpenEdit(item))"
            >
            </k-table-warning-btn>
            <k-table-error-btn
              v-if="hasPermission(['role.delete']) && item.roleId !== '2'"
              text="删除"
              @click="crudActionMX().deleteItem({ id: item.uuid })"
            >
            </k-table-error-btn>
          </template>
        </k-crud-table>
      </template>
    </k-crud-page>
    <k-crud-dialog
      v-model="groupEditDialog"
      title="角色分组管理"
      @close="groupEditDialog = false"
      @save="saveGroupEdit"
    >
      <template #dialogContent>
        <k-crud-table
          :paginationshow="false"
          :elevation="false"
          :items="groupItems"
          hide-default-footer
          :loading="groupEditOpenLoading"
          :headers="groupColumns"
        >
          <template #roleName="{ item }">
            <span v-if="item.roleName">
              {{ item.roleName }}
            </span>
          </template>
          <template #actions="{ item, index }">
            <v-icon
              v-if="item.roleId !== '1'"
              color="error"
              @click="delGroup(item, index)"
            >
              mdi-delete
            </v-icon>
          </template>
          <template #append>
            <tr>
              <td class="text-center info--text">
                <k-text-input
                  v-model="groupEditingName"
                  placeholder="请输入角色分组名称"
                />
              </td>
              <td>
                <v-icon color="primary" @click="newGroup"> mdi-plus </v-icon>
              </td>
            </tr>
          </template>
        </k-crud-table>
      </template>
    </k-crud-dialog>
    <k-crud-dialog
      v-model="crud.switch"
      :title="crud.title"
      @save="
        () => {
          crudActionMX().saveItem();
        }
      "
      @close="crud.switch = false"
    >
      <template #dialogContent>
        <v-form :ref="crud.form.ref">
          <div class="d-flex flex-column">
            <k-text-input
              v-model="cruddataMX.data.roleName"
              label="角色名称"
              class="my-2"
              :rules="[rules.required]"
            />
            <k-select
              v-model="cruddataMX.data.parentId"
              :items="crud.select.roleGroups.dataset"
              label="角色分组"
              :loading="crud.select.roleGroups.loading"
              :disabled="crud.select.roleGroups.loading"
              class="my-2"
            />
            <k-radio-group
              v-model="cruddataMX.data.status"
              label="启用状态"
              :items="statusItems"
              class="my-2"
            />
            <k-text-area
              v-model="cruddataMX.data.remarks"
              label="备注"
              class="my-2"
            />
          </div>
        </v-form>
      </template>
    </k-crud-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      roleGroups: '',
      groupEditToDelete: [],
      groupEditDialog: false,
      groupEditFormRef: 'groupEditFormRef',
      groupEditOpenLoading: false,
      groupItemsFromAPI: [],
      groupEditingName: '',
      groupColumns: [
        { text: '名称', value: 'roleName' },
        { text: '操作', value: 'actions', sortable: false },
      ],
      statusItems: [
        { label: '启用', text: '启用', value: '1' },
        { label: '未启用', text: '未启用', value: '-1' },
      ],
      crud: {
        name: '角色',
        title: '', // 在Mixin中被修改
        switch: false,
        uploading: false,
        loading: false,
        form: {
          ref: 'accountModalForm',
        },
        select: {
          roleGroups: {
            loading: false,
            current: 1,
            defaultPageSize: 10,
            data: [],
            dataset: [],
          },
        },
        default: {
          roleId: '',
          parentId: '', // 角色组
          roleName: '',
          type: '1',
          organizationId: '',
          status: '1',
          remarks: '',
          roleGroups: '',
        },
      },
      groupItems: [],
      columns: [
        { text: '角色名称', value: 'roleName' },
        { text: '分组', value: 'parentName', sortable: false },
        { text: '创建时间', value: 'createTime' },
        { text: '更新时间', value: 'updateTime' },
        { text: '状态', value: 'status' },
        { text: '操作', value: 'actions', sortable: false },
      ],
      rules: {
        required: (value) => !!value || 'Required.',
      },
    };
  },
  computed: {
    // groupItems() {
    //   var newItems = [];
    //   Array.prototype.push.apply(newItems, this.groupItemsFromAPI);
    //   newItems.push({
    //     roleName: '',
    //   });
    //   return newItems;
    // },
  },
  created() {
    this.crudInitMX(
      this.crud,
      {
        list: (params) => {
          params.roleType = 1;
          return this.$api.system.role.getRole(params);
        },
        add: this.$api.system.role.addRole,
        edit: this.$api.system.role.editRole,
        delete: this.$api.system.role.deleteRole,
      },
      {
        roleName: '',
        status: '',
      },
      {
        add: ['roleName', 'parentId', 'status', 'remarks', 'type'],
        edit: ['roleId', 'roleName', 'parentId', 'status', 'remarks', 'type'],
      },
      // true,
      () => {
        // this.callbackAfterInit();
      }
    );
  },
  methods: {
    callbackAfterInit() {
      let newItems = [];
      for (var i = 0; i < this.cruddataMX.dataList.length; i++) {
        var data = this.cruddataMX.dataList[i];
        data.statusModel = data.status === '1' ? true : false;
        data.id = data.uuid;
        data.roleId = data.uuid;
        newItems.push(data);
      }
      this.cruddataMX.dataList = newItems;
    },
    onOpenEdit() {
      this.crud.switch = true;
      this.crud.select.roleGroups.loading = true;
      this.$api.system.role
        .getRoleGroup()
        .then((res) => {
          this.crud.select.roleGroups.data =
            this.crud.select.roleGroups.data.concat(
              res.data.map((value) => {
                return value.name;
              })
            );
          this.crud.select.roleGroups.dataset =
            this.crud.select.roleGroups.dataset.concat(
              res.data.map((value) => {
                return { value: value.id, text: value.name };
              })
            );
        })
        .finally(() => {
          this.crud.select.roleGroups.loading = false;
        });
    },
    onOpenGroupEdit() {
      this.groupEditDialog = true;
      this.groupEditOpenLoading = true;
      this.groupEditToDelete = [];
      this.groupEditingName = '';
      this.$api.system.role
        .getRole({
          roleType: 0, //角色组
        })
        .then((res) => {
          // this.groupItemsFromAPI = res.data;
          this.groupItems = res.data;
        })
        .finally(() => {
          this.groupEditOpenLoading = false;
        });
    },
    newGroup() {
      if (this.groupEditingName) {
        // this.groupItemsFromAPI.push({
        //   roleName: this.groupEditingName,
        // });
        this.groupItems.push({
          roleName: this.groupEditingName,
        });
        this.groupEditingName = '';
      } else {
        this.$alert.warning('请输入角色分组名称！');
      }
    },
    delGroup(item, index) {
      if (item.uuid) {
        this.groupEditToDelete.push(item.uuid);
      }

      // this.groupItemsFromAPI.splice(index, 1);
      this.groupItems.splice(index, 1);
    },
    saveGroupEdit() {
      let groupEditToAdd = [];
      this.groupItems.forEach((value) => {
        if (!value.uuid && value.roleName) {
          groupEditToAdd.push(value.roleName);
        }
      });
      this.$api.system.role
        .editRoleGroup({
          deleteGroupIds: this.groupEditToDelete,
          addGroupNameList: groupEditToAdd,
        })
        .then((res) => {
          if (res.data && res.data.length > 0 && res.data[0].cantRemoveIds) {
            this.$alert.warning(
              '角色组更新部分未成功，请重新打开对话框确认结果。'
            );
            return;
          }
          this.$alert.success('更新角色组成功');
        })
        .catch((res) => {
          this.$alert.error(res.message);
        })
        .finally(() => {
          this.groupEditDialog = false;
        });
    },
    onChangeStatus(item) {
      // eslint-disable-next-line no-debugger
      debugger;
      item.status = item.status === '1' ? '-1' : '1';
      item.roleId = item.uuid;
      item.loading = true;
      this.$api.system.role
        .editRole(item)
        .then((res) => {
          if (res.code === 0 || res.status === 200) {
            this.crudActionMX().list();
          }
          // for (var i = 0; i < this.cruddataMX.dataList.length; i++) {
          //   var item = this.cruddataMX.dataList[i];
          //   if (item.uuid === res.data[0].uuid) {
          //     Object.assign(item, res.data[0]);
          //     item.loading = false;
          //   }
          // }
        })
        .catch((e) => {
          // TODO 值还原
          var origin = e.origin;
          origin.loading = false;
        })
        .finally(() => {
          // this.$forceUpdate();
        });
      // Vue.prototype.kalert({
      //   content: 'hello',
      // });
    },
  },
};
</script>
